<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
      <page-heading
        heading="New Work Order"
      />
    </column>
    <column class="is-narrow is-flex is-align-items-center">
      <div class="is-flex is-align-items-center">
        <router-link :to="{ name: 'work-orders' }">Back</router-link>
        <action-button @click="openTemplateSelector" class="is-small is-rounded ml-3">Select a Template</action-button>
      </div>
    </column>
  </columns>
  <form>
    <columns>
      <column>
        <data-selector
          classes="is-medium is-rounded"
          required
          searchable
          :items="customer_list"
          :value="workOrder.customer_uuid"
          @input="updateCustomer"
          :error="$root.errors.customer_uuid"
          value-key="uuid"
          label-key="name">
          Customer
        </data-selector>
      </column>
      <column>
        <data-selector
          searchable
          classes="is-medium is-rounded"
          :error="$root.errors.location_uuid"
          :items="customerLocations"
          :value="workOrder.location_uuid"
          @input="updateLocation"
          value-key="uuid"
          label-key="name"
          required>
          Location
        </data-selector>
      </column>
      <column>
        <data-selector
          searchable
          classes="is-medium is-rounded"
          :error="$root.errors.team_uuid"
          :items="availableTeams"
          :value="workOrder.team_uuid"
          @input="updateTeam"
          value-key="uuid"
          label-key="name"
          required>
          Team
          <!-- <template #right>
            <action-button
              :disabled="!workOrder.customer_id"
              class="is-medium is-rounded">
              <icon icon="plus"/>
            </action-button>
          </template> -->
        </data-selector>
      </column>
      <column class="is-narrow">
        <switch-input
          classes="is-medium is-rounded"
          description="Customer contacts will not receive notifications"
          :value="workOrder.internal"
          @input="toggleInternal"
          :error="$root.errors.internal"
          required>
          Internal Only
        </switch-input>
      </column>
    </columns>
    <columns>
      <column class="is-6">
        <text-input
          classes="is-medium is-rounded"
          required
          :error="$root.errors.reference"
          :value="workOrder.reference"
          @input="updateReference">
          Reference
        </text-input>
      </column>
      <column>
        <data-selector
          searchable
          classes="is-medium is-rounded"
          :error="$root.errors.classification_uuid"
          :items="work_order_classifications"
          :value="workOrder.classification_uuid"
          @input="updateClassification"
          value-key="uuid"
          label-key="title"
          required>
          Classification
        </data-selector>
      </column>
      <column>
        <data-selector
          classes="is-medium is-rounded"
          :error="$root.errors.priority_id"
          :items="priorities"
          :value="workOrder.priority_id"
          @input="updatePriority"
          value-key="id"
          label-key="level"
          required>
          Priority
        </data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <h4 class="title is-4">Scheduling</h4>
        <columns>
          <column class="is-6">
            <date-picker
              classes="is-medium is-rounded"
              :error="$root.errors.starting_at"
              clearable
              required
              :value="workOrder.starting_at"
              @input="updateStartingDate">
              Starting Date/Time
            </date-picker>
          </column>
          <column>
            <date-picker
              classes="is-medium is-rounded"
              :error="$root.errors.ending_at"
              clearable
              :value="workOrder.ending_at"
              @input="updateEndDate">
              Anticipated End Date/Time
            </date-picker>
          </column>
        </columns>
      </column>
    </columns>
    <columns>
      <column>
        <text-area
          classes="is-rounded"
          :value="workOrder.brief"
          @input="updateBrief"
          placeholder="Any information that the team may require to complete the work ahead.">
          Briefing
        </text-area>
      </column>
    </columns>
    <columns>
      <column>
        <submit-button
          class="is-medium is-rounded"
          :working="creating"
          :disabled="$root.cant('manage-work-orders')"
          @submit="createWorkOrder">
          Open Work Order
        </submit-button>
        <p class="help is-danger" v-if="$root.cant('manage-work-orders')">Please contact your administrator to adjust your permissions.</p>
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { find, flatMap } from 'lodash'
import { priority } from '@/api'
import { selectWorkOrderTemplate } from '@/modals'

export default {

  data: () => ({
    loading: true,
    creating: false,
    priorities: []
  }),

  async created() {
    await priority.loadPriorityList(({data}) => this.priorities = data)
    await this.loadCustomerList()
    await this.loadTeamList()
    await this.loadWorkOrderClassificationList()
    this.loading = false
  },

  beforeDestroy() {
      this.$store.commit('workOrder/clearWorkOrder')
  },

  methods: {
    ...mapActions('customer', ['loadCustomerList']),
    ...mapActions('team', ['loadTeamList']),
    ...mapActions('workOrderClassification', ['loadWorkOrderClassificationList']),
    ...mapMutations('workOrder', [
      'updateCustomer',
      'updateLocation',
      'updateReference',
      'updatePriority',
      'updateTeam',
      'updateClassification',
      'updateStartingDate',
      'updateEndDate',
      'updateBrief',
      'toggleInternal'
    ]),
    createWorkOrder() {
      this.creating = true
      this.$store.dispatch('workOrder/create').then(() => {
        this.creating = false
        this.$toast.success('Work order opened successfuly')
        this.$router.push({
          name: 'work-orders'
        })
      }, (error) => {
        if (error.response.status === 406) {
          this.$alert('Account Limit Reached', error.response.data.limits)
        }
        this.creating = false
      })
    },
    openTemplateSelector() {
      selectWorkOrderTemplate().then(async template => {
        if (template) {
          await this.$store.dispatch('workOrder/populateFromTemplate', template)
          this.$store.commit('workOrder/updateTeam', null)
          this.$nextTick(() => {
            this.$store.commit('workOrder/updateTeam', template.team.uuid)
          })
        }
      })
    }
  },

  computed: {
    ...mapGetters('workOrder', ['workOrder']),
    ...mapGetters('customer', ['customer_list']),
    ...mapGetters('team', ['team_list']),
    ...mapGetters('workOrderClassification', ['work_order_classifications']),
    activeCustomer() {
      return find(this.customer_list, customer => customer.uuid === this.workOrder.customer_uuid)
    },
    customerLocations() {
      return this.activeCustomer ? this.activeCustomer.locations : []
    },
    availableTeams() {
      return this.activeCustomer ? this.team_list.filter(team => {
        const branch_ids = flatMap(this.activeCustomer.locations.map(location => {
          return location.branches.map(branch => branch.uuid)
        }))
        return branch_ids.includes(team.branch_uuid)
      }) : []
    }
  }

}
</script>